function numberWithSpaces(x) {
  if (!x) return

  return `<span style="white-space:nowrap">${x
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>`
}

export default function() {
  const get = path => this.$path(path, this.$store.state)
  const geti18n = (key, index = 1) => this.$tc(key, index)

  const isSold = get('Residential.item.status') === 'sold'
  const isUpcoming = get('Residential.item.status') === 'upcoming'

  const getProperty = property => {
    const properties = get('Residential.item.properties')

    if (!properties || !properties.length) return

    const prop = properties.find(p => p.key === property)
    if (!prop || !prop.formatted) return
    return prop.formatted
  }

  const getPrice = () => {
    if (isSold) return 'Solgt'

    const value = get('Residential.item.sale.value')
    if (!value) return
    return 'kr ' + numberWithSpaces(value)
  }

  const getJointDebt = () => {
    const value = get('Residential.item.sale.jointDebt')
    if (!value) return
    if (isSold) return '-'
    return 'kr ' + numberWithSpaces(value)
  }

  const jointCost = () => {
    const properties = get('Residential.item.properties')
    if (!properties || !properties.length) return

    const prop = properties.find(p => p.key === 'jointCostsPerMonth')

    if (!prop || !prop.value) return
    if (isSold) return '-'

    return `kr ${numberWithSpaces(prop.value.replace('kr', '').trim())} /mnd`
  }

  const getTotalPrice = () => {
    if (isSold) return '-'

    const value = get('Residential.item.sale.value')
    const jointDebt = get('Residential.item.sale.jointDebt') || 0

    const totalCost = Math.floor(
      (get('Residential.item.customFields.total-cost') || []).reduce((acc, cost) => {
        return acc + cost.value
      }, 0),
    )

    if (!value) return

    return 'kr ' + numberWithSpaces(value + jointDebt + totalCost)
  }

  const removeHeading = value => {
    if (!value) return ''
    return value.replace(new RegExp(`<h[2]>.*?</h[2]>`), '')
  }

  const braI = getProperty('useableInternalArea')

  let keyStats = [
    { value: braI ? braI : getProperty('primaryRoomArea'), label: braI ? 'BRA-I' : 'Primærrom' },
    { value: getProperty('useableArea'), label: 'Bruksareal' },
    !isUpcoming ? { value: getPrice(), label: 'Pris' } : null,
    { value: getProperty('ownershipType'), label: 'Eierform' },
    !isUpcoming ? { value: getJointDebt(), label: 'Fellesgjeld' } : null,
    !isUpcoming ? { value: jointCost(), label: 'Fellesutgifter' } : null,
    !isUpcoming ? { value: getTotalPrice(), label: 'Totalpris' } : null,
    { value: getProperty('propertyType'), label: 'Boligtype' },
    { value: getProperty('plotArea'), label: 'Tomteareal' },
    { value: getProperty('numberOfBedrooms'), label: 'Soverom' },
    { value: getProperty('numberOfBathrooms'), label: 'Bad' },
    { value: getProperty('floor'), label: 'Etasje' },
    { value: getProperty('energyLabel'), label: 'Energimerke' },
  ].filter(item => item && item.value)

  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        style: 'padding: 0',
        id: 'section-header',
        class: 'section-header',
      },

      blocks: {
        default: [
          {
            component: 'Slider',
            props: {
              class: 'residential__cover ',
              size: 'contain',
              autoPlay: false,
              photoSwipe: false,
              navIcon: {
                left: ['fas', 'chevron-left'],
                right: ['fas', 'chevron-right'],
              },
            },
            data: get('Residential.item.media.gallery'),
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Residential.item.name'),
      props: {
        width: 'medium',
        id: 'section-info',
        class: 'residential__description-section',
        style: 'padding-top:2rem;',
      },
      blocks: {
        default: [
          {
            component: 'ReadMore',
            condition: () => (get('Residential.item.description') || '').length > 1400,
            props: {},
            blocks: {
              default: {
                component: 'Text',
                props: {
                  class: 'residential__description',
                },
                data: {
                  content: `<h2>${get('Residential.item.name')}</h2>
                  <p class="residential__description-heading">${get(
                    'Residential.item.customFields.preamble',
                  ) || ''}</p>
                  ${removeHeading(get('Residential.item.description'))}`,
                },
              },
            },
          },
          {
            component: 'Text',
            condition: () => !((get('Residential.item.description') || '').length > 1400),
            props: {
              class: 'residential__description',
            },
            data: {
              content: `<h2>${get('Residential.item.name')}</h2>
              <p class="residential__description-heading">${get(
                'Residential.item.customFields.preamble',
              ) || ''}</p>
              ${removeHeading(get('Residential.item.description'))}`,
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: keyStats.length,
      props: {
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'KeyStats',
            props: {
              columns: 4,
            },

            data: {
              content: keyStats.splice(0, 8),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Residential.item.customFields.sales-assignment.value'),
      props: {
        width: 'medium',
        style: 'padding-top:2rem;',
      },
      blocks: {
        default: [
          {
            component: 'ShareButton',
            props: {},
            blocks: {
              default: [
                {
                  component: 'Button',
                  data: {
                    label: 'Komplett digital salgsoppgave',
                    href: get('Residential.item.customFields.sales-assignment.value'),
                    theme: 'primary',
                    target: '_blank',
                    tag: 'a',
                    icon: ['fas', 'external-link'],
                  },
                },
              ],
            },

            data: {
              label: 'Del',
              theme: 'secondary',
              tag: 'button',
              icon: ['far', 'share-square'],
            },
          },
        ],
      },
    },

    {
      condition: get('Residential.item.media.floorplan.length'),
      component: 'Section',
      props: {
        width: get('Residential.item.media.floorplan.length') > 1 ? 'large' : 'medium',
        style: `${
          get('Residential.item.media.floorplan.length') > 1 ? '--section-padding-inline: 0;' : ''
        }`,
        class: 'section-floorplan kpb-section--width-full-mobile',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: `<h2>${geti18n('floorplan', 2)}</h2>`,
            },
          },
          {
            component: 'Slider',
            props: {
              class: 'slider__gallery',
              aspectRatio: '16/9',
              size: 'contain',
              navigationPlacement:
                get('Residential.item.media.floorplan.length') > 1 ? 'outside' : 'center',
              //   class: 'residential__floorplan',
              autoPlay: false,
              photoSwipe: true,
              navIcon: {
                left: ['fas', 'chevron-left'],
                right: ['fas', 'chevron-right'],
              },
            },
            data: get('Residential.item.media.floorplan'),
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Residential.item.media.gallery.length'),
      props: {
        width: get('Residential.item.media.gallery.length') > 1 ? 'large' : 'medium',
        style: `${
          get('Residential.item.media.gallery.length') > 1 ? '--section-padding-inline:0' : ''
        }`,
        class: 'section-gallery kpb-section--width-full-mobile',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: `<h2>Bildegalleri</h2>`,
            },
          },
          {
            component: 'Slider',
            props: {
              class: 'slider__gallery',
              aspectRatio: '16/9',
              autoPlay: false,
              photoSwipe: true,
              navigationPlacement:
                get('Residential.item.media.gallery.length') > 1 ? 'outside' : 'center',
              navIcon: {
                left: ['fas', 'chevron-left'],
                right: ['fas', 'chevron-right'],
              },
            },
            data: get('Residential.item.media.gallery'),
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'small',
        theme: 'primary',
        class: 'residential-cta-section',
      },
      condition: get('Residential.item.customFields.buy-url.value') && get('Residential.item.status') !== 'upcoming',
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    textAlign: 'center',
                  },
                  data: {
                    content:
                      '<h2>Kjøpe boligen?</h2><p>Er du klar for å kjøpe boligen? Kjøp den raskt og enkelt her!',
                  },
                },
                {
                  component: 'Button',
                  data: {
                    label: 'Les mer',
                    theme: 'secondary',
                    href: get('Residential.item.customFields.buy-url.value'),
                    tag: 'a',
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        class: 'roles-section',
      },
      blocks: {
        default: [
          {
            component: 'Grid',
            props: {
              class: 'roles-layout',
              columns: 2,
            },
            blocks: {
              default: [
                {
                  component: 'Openhouse',
                  data: {
                    content: get('Residential.item.openhouses'),
                    leadUrl: get('Residential.item.customFields.lead-url.value'),
                    openhouseUrl: get('Residential.item.customFields.openhouse-url.value'),
                  },
                },

                {
                  component: 'Roles',
                  condition: get('Root.item.roles.salesman'),
                  data: {
                    content: get('Root.item.roles.salesman'),
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        style: 'padding-block: 0',
      },
      blocks: {
        default: {
          component: 'HorizontalLine',
        },
      },
    },

    {
      component: 'Section',
      condition: get('Residential.item.project.flatfinders.length'),
      props: {
        width: 'medium',
        id: 'flatfinder',
        class: 'section-flatfinder kpb-section--width-full-mobile',
        style: 'padding-bottom:1rem;',
      },
      blocks: {
        default: [
          {
            component: 'FlatfinderCustom',
            props: {
              properties: get('Project.item.metadata.siteSettings.featuredResidentialProperties'),

              value: get('Residential.item.project.flatfinders'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Residential.item.project.id'),
      props: {
        width: 'medium',
        id: 'residentials',
        class: 'section-residentials',
        style: 'padding-top:1rem',
      },
      blocks: {
        default: [
          {
            component: 'ResidentialsList',
            props: {
              project: get('Residential.item.project.id'),
              properties: [
                'floor',
                'numberOfBedrooms',
                'useableArea',
                braI ? 'useableInternalArea' : 'primaryRoomArea',
                'plotArea',
                'propertyType',
                'numberOfParkingSpots',
              ],
            },
          },
        ],
      },
    },
  ]
}
